import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/rc-slider/assets/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/CommonClient.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/SiteHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartWrapper"] */ "/app/src/context/cart-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/fonts/line-awesome-1.3.0/css/line-awesome.css");
;
import(/* webpackMode: "eager" */ "/app/src/images/logo-light.svg");
;
import(/* webpackMode: "eager" */ "/app/src/images/logo/dropshipping-logo.png");
;
import(/* webpackMode: "eager" */ "/app/src/images/socials/facebook.svg");
;
import(/* webpackMode: "eager" */ "/app/src/images/socials/Instagram.svg");
;
import(/* webpackMode: "eager" */ "/app/src/styles/index.scss");
